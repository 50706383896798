<template>
	<div id="commonNav">
		<div class="top no-transparentAni" id="isTransparent">
			<header>
				<nav class="flex__row_vertical">
					<!-- <a class="navbar-brand logo area_header" name="area_header" href="/"> -->
					<a href="/" class="navbar-brand logo area_header">
						<div class="logo" id="logos">
							<img :src="logo_src">
						</div>
					</a>
					<!-- <a class="btn btn-standard3 btnDemo area_header" href="/cooperation">马上合作</a> -->
					<a class="btnToggle" onclick="m_bfc.changeMode()"><em></em></a>
					<!--nav-menu-->
					<ul class="nav-menu">
						<template v-for="item,index in nav_menu">
							<template v-if="item.sub_items === undefined">
								<li class="menu" onclick="m_bfc.changeMode('route')" :key="index">
									<template v-if="item.action">
										<a :href="item.href" class="area_header area_header_title" @click="item.action">{{item.title}}</a>
									</template>
									<template v-else>
										<a :href="item.href" class="area_header area_header_title" :target="item.href.startsWith('http') ? '_blank': '_self'">{{item.title}}</a>
									</template>
								</li>
							</template>
							<template v-else>
								<li class="menu" :key="index">
									<a class="dropdown" name="area_header" href="javascript:void(0);"
										onclick="m_bfc.tagNav(event)" v-html="item.title"></a>
									<ul class="submenu">
										<li v-for="sub_item,index1 in item.sub_items" class="menu" :key="index1">
											<a name="area_header" :href="sub_item.href" v-html="sub_item.title"></a>
										</li>
									</ul>
								</li>
							</template>
						</template>
					</ul>
				</nav>
			</header>
		</div>
	</div>
</template>

<script>	
	export default {
		data() {
			return {
				logo_src: '/images/mobile/nav_logo.png',
				nav_menu: [{
					title: '首页',
					href: '/',
				}, {
					title: '核心业务',
					sub_items: [{
						title: '出海营销',
						href: '#'
					}, {
						title: '亚马逊代运营',
						href: '#'
					}, {
						title: '明星品牌代运营',
						href: '#'
					}]
				},
				{
					title: '权威认可',
					href: 'https://assets.kpmg.com/content/dam/kpmg/cn/pdf/zh/2019/09/leading-chinese-cross-border-brands-the-top-50.pdf',
				},
				{
					title: '马上合作',
					href: '/contact',
				}]
			}
		},
		mounted() {
			this.$nextTick(() => {
				this.loadExternalScript('/js/m-header.js?v=' + process.env.VUE_APP_VERSION)
				this.loadExternalScript('/js/m-common.js?v=' + process.env.VUE_APP_VERSION)
				this.loadExternalScript('/js/flexable.js?v=' + process.env.VUE_APP_VERSION)
			})
			document.qu
		},
		methods: {
			loadExternalScript(src) {
				// 创建一个 <script> 标签
				const script = document.createElement('script');
				script.src = src;
				script.onload = () => {
					console.log('外部脚本已加载并执行');
					// 脚本加载完成后执行你需要的逻辑
				};
				script.onerror = () => {
					console.error('外部脚本加载失败');
				};
				// 将 <script> 标签插入到 <head> 或 <body> 中
				document.head.appendChild(script);
			}
		}
	}
</script>

<style>
</style>