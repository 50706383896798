<template>
	<footer>
	    <div class="footer">
	        <div class="footer_con">
	            <div class="title din">CONTACT US</div>
	            <div class="icon" style="width: auto;">
	                <a v-for="info, idx in infos" href="javascript:void(0)" @click="showOverlay(info.cls)">
	                    <img :src="info.src" alt="手机icon" width="22px">
	                </a>
	            </div>
	            <div v-html="text1"></div>
	            <div>{{text2}}</div>
	        </div>
	        <div class="overlay">
	            <div class="bg" onclick="m_bfc.closeFooterOverlay()"></div>
	            <div v-for="info, idx in infos" :class="info.cls" :key="idx">
	                <div>
	                    <p>{{info.title}}</p>
						<template v-if="info.description">
							<p v-html="info.description"></p>
						</template>
	                    <template v-else-if="info.info_src">
							<img :src="info.info_src" alt="">
						</template>
	                </div>
	            </div>
	        </div>
	    </div>
	</footer>
</template>

<script>
	export default {
		data() {
			return {
				infos: [
				// {
				// 	src: '/images/mobile/footer_tel.png',
				// 	title: '联系电话',
				// 	description: '<a href="tel:010—56478666">010—56478666</a>',
				// 	cls: 'tel'
				// }, 
				{
					src: '/images/mobile/footer_email.png',
					cls: 'email',
					title: '邮箱',
					description: 'contact@ydbis.com'
				},
				// {
				// 	src: '/images/mobile/footer_wx.png',
				// 	cls: 'ewm',
				// 	title: '官方微信公众号',
				// 	info_src: '/images/mobile/footer_ewm.jpg'
				// },
				],
				text1: 'Copyright ©2015-2024 YDBis Corporation, All Rights Reserved',
				text2: ''//'增值电信业务经营许可证：京B2-20210408'
			}
		},
		methods: {
			showOverlay(idx) {
				window.m_bfc && window.m_bfc.showOverlay(idx)
			}
		}
	}
</script>

<style>
</style>