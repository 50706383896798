<template>
	<div class="header" style="display: block;">
		<div class="header_outer">
			<div class="header_inner">
				<div class="navs">
					<div class="logo">
						<a href="/">
							<img :src="logo_src" alt="logo" width="100%">
						</a>
					</div>
					<!-- <div class='navs_outer_left'>
						<div class='navs_outer_right'> -->
					<ul class="navs_inner">
						<template v-for="item,index in nav_menu">
							<template v-if="item.temp==='link'">
								<li class="nav_item">
									<a :href="item.href" class="btn btn-a" :data-fade-out="item.data_fade_out" :target="item.href.startsWith('http') ? '_blank': '_self'">
										{{item.title}}
									</a>
								</li>
							</template>
							<template v-else-if="item.temp==='hover'">
								<li class="nav_item" :data-fade-to="item.data_fade_out" data-target="#mt"
									@mouseout="cur_index=-1" @mouseover="cur_index=item.data_fade_out">
									<a href="javascript:void(0);" class="btn btn-a is_active commonAni2 commonAni3"
										:data-fade-toa="item.data_fade_out" data-target="#mt">
										{{item.title}}
									</a>
								</li>
							</template>
							<template v-else-if="item.temp==='button'">
								<li class="nav_item">
									<div class="text-button">
										<a href="javascript:void(0);" @click="item.action"
											:data-fade-out="item.data_fade_out">{{item.title}}</a>
									</div>
								</li>
							</template>
						</template>

					</ul>
					<!-- </div>
					</div>
					<div class='clear'></div> -->
				</div>
				<div class="sub_navs mt" data-ride="mt" id="mt">
					<div class="sub_mask slide" style="left: 0%;"></div>
					<template v-for="item,index in nav_menu">
						<template v-if="item.sub_items !== undefined">
							<div class="sub_nav" :index="item.data_fade_out" :class="item.sub_items_cls">
								<div class="sub_outer">
									<div class="sub_inner">
										<template v-if="item.sub_items_cls !== 'nav_copy'">
											<div v-for="sub_item, index1 in item.sub_items" class="sub_item" :key="index1">
												<a :href="sub_item.href">
													<div class="ac_trigger" style="opacity: 1;">
														<div class="copy">
															<p>{{sub_item.title}}</p>
															<p class="din">{{sub_item.en_title}}</p>
														</div>
														<div class="overlay"></div>
														<img :src="sub_item.img_src" class="img" data-ride="zoom" style="top: 41.5px; left: 0px;">
													</div>
												</a>
											</div>
										</template>
										<template v-else-if="item.sub_items_cls === 'nav_copy'">
											<div v-for="sub_item, index1 in item.sub_items" class="sub_item" :key="index1">
												<div class="ac_trigger" style="opacity: 1;">
													<div class="product_plan">
														<h3 v-if="sub_item.title !== undefined">{{sub_item.title}}</h3>
														<ul>
															<template v-for="ssub_item,index2 in sub_item.ssub_items">
																<li v-if="ssub_item!==null" :key="index2">
																	<template v-if="ssub_item.action">
																		<a :href="ssub_item.href" @click="ssub_item.action">
																			<span class="sspan" v-html="ssub_item.title"></span>
																			<img src="/images/pc/navs_arrow_black.png"
																				class="arrow_black" alt="黑箭头" style="">
																			<img src="/images/pc/navs_arrow.png"
																				class="arrow_gray" alt="箭头">
																		</a>
																	</template>
																	<template v-else>
																		<a :href="ssub_item.href">
																			<span class="sspan" v-html="ssub_item.title"></span>
																			<img src="/images/pc/navs_arrow_black.png"
																				class="arrow_black" alt="黑箭头" style="">
																			<img src="/images/pc/navs_arrow.png"
																				class="arrow_gray" alt="箭头">
																		</a>
																	</template>
																</li>
																<li v-else style="opacity:0;">
																	<!--只用来占位置-->
																	<a href="javascript:void(0);" style="cursor:default">
																		<span class="sspan"></span>
																		<img src="/images/pc/navs_arrow_black.png"
																			class="arrow_black" alt="黑箭头">
																		<img src="/images/pc/navs_arrow.png"
																			class="arrow_gray" alt="箭头">
																	</a>
																</li>
															</template>
														</ul>
													</div>
												</div>
											</div>
										</template>
									</div>
								</div>
							</div>
						</template>
					</template>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				logo_src: '/images/pc/logo.png',
				nav_menu: [{
					title: '首页',
					href: '/',
					temp: 'link',
					data_fade_out: 0
				}, {
					title: '核心业务',
					temp: 'hover',
					data_fade_out: 0,
					sub_items_cls: '',
					sub_items: [{
						title: '出海营销',
						en_title: 'OVERSEAS MARKETING',
						img_src: '/images/pc/img1.jpg',
						href: '#'
					}, {
						title: '亚马逊代运营',
						en_title: 'AMAZON OPERATION SERVICES',
						img_src: '/images/pc/img2.jpg',
						href: '#'
					}, {
						title: '明星品牌代运营',
						en_title: 'STAR BRAND OPERATION SERVICES',
						img_src: '/images/pc/img3.jpg',
						href: '#'
					}]
				}, {
					title: '权威认可',
					temp: 'link',
					data_fade_out: 0,
					href: 'https://assets.kpmg.com/content/dam/kpmg/cn/pdf/zh/2019/09/leading-chinese-cross-border-brands-the-top-50.pdf',
				}, {
					title: '马上合作',
					temp: 'button',
					data_fade_out: 2,
					action: () => bfcMedia && bfcMedia.cooperationIsShow()
				}]
			}
		},
		mounted() {
			this.$nextTick(() => {
				this.loadExternalScript('/js/chris.js?v='+process.env.VUE_APP_VERSION)
			})
		},
		methods: {
			loadExternalScript(src) {
				// 创建一个 <script> 标签
				const script = document.createElement('script');
				script.src = src;
				script.onload = () => {
					console.log('外部脚本已加载并执行');
					// 脚本加载完成后执行你需要的逻辑
				};
				script.onerror = () => {
					console.error('外部脚本加载失败');
				};
				// 将 <script> 标签插入到 <head> 或 <body> 中
				document.head.appendChild(script);
			}
		}
	}
</script>

<style>

</style>