<template>
	<footer>
	    <div class="footer flex__row_vertical" style="height: 220px;">
	        <div class="footer_con">
	            <div class="footer_con_left">
	                <ul class="footer_con_nav" style="margin-bottom: 25px;">
	                    <li v-for="nav in nav_list" class="swiper-no-swiping"><a :href="nav.href" class="swiper-no-swiping" :target="nav.href.startsWith('http') ? '_blank' : '_self'">{{nav.title}}</a></li>
	                </ul>
	                <div class="address">
	                    <p class="swiper-no-swiping" v-if="false">地址：{{address_info.address}}</p>
	                    <p class="swiper-no-swiping" v-if="false">电话：{{address_info.phone}}</p>
	                    <p class="swiper-no-swiping">邮箱：{{address_info.mail}}</p>
	                </div>
	                <div class="recordNum swiper-no-swiping" style="margin-top: 0px;" v-html="copyrights"></div>
	            </div>
	            <div class="footer_con_mid" style="display:none;">
	                <div class="footer_logo">
	                    <img :src="footer_logo" alt="logo" width="100%">
	                </div>
	                <!-- <div class="footer_wx">
	                    <img :src="footer_wx.logo" alt="微信icon" width="18px">
	                    <span class="swiper-no-swiping">{{footer_wx.logo.title}}</span>
	                </div>
	                <div class="footer_wx_ewm">
	                    <img :src="footer_wx_ewm" alt="微信官方公众号二维码" width="120px">
	                </div> -->
	            </div>
	            <div class="footer_con_right">
	                <a href="javascript:void(0);">
	                    <img src="/images/pc/footer_goTop.png" alt="返回顶部" width="30px">
	                </a>
	            </div>
	        </div>
	    </div>
	</footer>
</template>

<script>
	export default {
		data() {
			return {
				copyrights: 'Copyright ©2015-2024 YDBis Corporation, All Rights Reserved',
				footer_logo: '/images/pc/footer_logo.png',
				footer_wx: {
					logo: '/images/pc/footer_wx_icon.png',
					title: '蓝标传媒BlueMedia'
				},
				footer_wx_ewm: '/images/pc/footer_wx_ewm.png',
				nav_list: [{
					"title": "出海营销",
					"href": "#"
				}, {
					"title": "亚马逊代运营",
					"href": "#"
				}, {
					"title": "明星品牌代运营",
					"href": "#"
				}, {
					"title": "国际物流与货运代理",
					"href": "#"
				}, {
					"title": "权威认可",
					"href": "https://assets.kpmg.com/content/dam/kpmg/cn/pdf/zh/2019/09/leading-chinese-cross-border-brands-the-top-50.pdf"
				}],
				address_info: {
					address: '北京市朝阳区酒仙桥北路恒通国际创新园C9c',
					phone: '010—00000000',
					mail: 'contact@ydbis.com'
				}
			}
		}
	}
</script>

<style>
</style>